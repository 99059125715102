// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "channels";

Rails.start();
global.jQuery, global.$ = require("jquery");

require("../plugins/grayscale");

import 'bootstrap';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.ru';
import 'leaflet/dist/leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'readmore-js/readmore.min';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/regular';
import 'lodash';

var filterQuery = function() {
  var value = $(this).val();
  if (value.length > 2 || value.length === 0) {
    Rails.fire($('#search')[0], 'submit');
  }
};

$(document).on('click', '.filter-open, .bi-x-circle', function(){
  $('.form').addClass('d-none');
  $('.filter-open').addClass('filter');
  $('.filter-open').removeClass('filter-open');
});

$(document).on('click', '.filter', function(){
  $('.form').removeClass('d-none');
  $('.filter').addClass('filter-open');
  $('.filter').removeClass('filter');
});

$(document).on('click', '.subscribe', function(){
  $('#subscribe').removeClass('d-none');
  $('.subscribe').addClass('d-none');
  $('.document').addClass('d-none');
});

$(document).on('click', '.collapsed', function(){
  $('.readmore').readmore({
    collapsedHeight: 120,
    moreLink: '<a href="#" class="text-secondary">читать далее</a>',
    lessLink: '<a href="#" class="text-secondary">свернуть</a>'
  });
});

$(function() {
  $('.custom-checkbox').on('click', function(){
    Rails.fire($('#search')[0], 'submit');
  });

  $('.btn-outline-circle').on('click', function(){
    $("#query").val('');
    Rails.fire($('#search')[0], 'submit');
  });

  $("#query").on("keyup", _.debounce(filterQuery, 300));

  $('#agree').on('click', function(){
    if ($(this).is(':checked')) {
      $("input[type='submit']").removeAttr('disabled');
    } else {
      $("input[type='submit']").attr('disabled', 'disabled');
    }
  });
});